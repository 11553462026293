
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import { ChatRoomClient } from '@/services/Services';
import Removable from '@/components/removable.vue';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
        Removable
    }
})
export default class WhoCanModal extends Vue {

    @Prop() chatRoomIdentifier: string;
    @Prop() model: string[];
    @Prop() saveCb: (data: VM.NameIdentifierSelected[]) => void;

    contacts: VM.NameIdentifierSelected[] = [];
    localSelected: VM.NameIdentifierSelected[] = [];
    search: string = "";

    created(){
        ChatRoomClient.getParticipants(this.chatRoomIdentifier)
        .then(x => {
            this.contacts = x;
            this.contacts.forEach(x => {
                if(this.model.findIndex(c => x.identifier == c) > -1){
                    x.selected = true;
                    this.localSelected.push(x);
                }
            })
        })
    }
    
    select(item: VM.NameIdentifierSelected){
        let ind = this.localSelected.findIndex(x => x.identifier == item.identifier);
        if(ind > -1){
            item.selected = false;
            this.localSelected.splice(ind, 1);
        } else {
            item.selected = true;
            this.localSelected.push(item);
        }
    }
    
    get filtered(){
        if(!this.search.trim()) {
            return this.contacts;
        } else {
            return this.contacts.filter(x => x.name.toLowerCase().indexOf(this.search.toLowerCase().trim()) > -1);
        }
    }

    save(){
        this.saveCb(this.localSelected);
    }

}

