
import { Vue, Options } from 'vue-class-component';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import store from '@/store';
import FileInput from '@/components/fileInput.vue';
import { LocalizationServices } from '@/services/LocalizationServices';
import { Prop , Watch} from 'vue-property-decorator';
import { ChatRoomClient } from '@/services/Services';



@Options({
    components: {
        FileInput
    }
})
export default class ChatRoomDetailEditModal extends Vue {

    @Prop() chatRoomVm: OM.ChatRoomVm;
    placeholder: string = "";

    vm: VM.UpdateChatRoomVm = new VM.UpdateChatRoomVm();
    created(){
        this.vm.identifier = this.chatRoomVm.identifier;
        this.vm.name = this.chatRoomVm.name;
        this.vm.description = this.chatRoomVm.description;
        this.vm.image = <any>store.state.cacheBuster(this.chatRoomVm.image);

        this.placeholder = LocalizationServices.getLocalizedValue("app_scriviunadescrizione","Scrivi una descrizione");

        this.makePreview();
    }
    
    preview: any = null;
    @Watch('vm.image')
    onImageChange(next, prev){
        this.makePreview();
    }
    makePreview(){
        if(this.vm.image){
            if(typeof this.vm.image == "string"){
                this.preview = this.vm.image;
            } else {
                var reader = new FileReader();
                reader.onload = (e: any) => {
                    this.preview = e.target.result;
                }
                reader.readAsDataURL(this.vm.image);
            }
        }
    }

    save(){
        ChatRoomClient.updateChatRoom(this.vm)
        .then(x => {

            ChatRoomClient.updateChatroomImage(this.vm.identifier, this.vm.image)
            .then( y => {
                
            })

            if(!store.state.userImageRefresher[this.vm.identifier])
                store.state.userImageRefresher[this.vm.identifier] = 1;
            else
                store.state.userImageRefresher[this.vm.identifier]++;
                
            this.$emit('close')
        });
    }

}

