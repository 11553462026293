
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import * as Const from '@/const';

@Options({})
export default class SettingsModal extends Vue {

    @Prop() title: string;
    @Prop() infoLabel: string;
    @Prop() invitationLabel: string;
    @Prop() contentLabel: string;
    @Prop() permissions: OM.PermissionSettings;
    @Prop() saveCb: (model: OM.PermissionSettings) => void;

    groups: Const.PermissionsGroup[] = Const.PermissionsGroup.GetAll();

    save(){
        this.saveCb(this.permissions);
    }

}

