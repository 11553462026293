
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { ChatRoomClient } from '@/services/Services';
import Toggler from '@/components/toggler.vue';
import EditModal from '../modals/editModal.vue';
import SettingsModal from '../modals/settingsModal.vue';
import store from '@/store';
import * as OM from '@/model';

@Options({
    components: {
        Toggler,
    }
})
export default class ChatRoomDetail extends Vue {

    loaded: boolean = false;
    chatRoomVm: OM.ChatRoomVm = null;
    isAdmin: boolean = false;

    created(){
        this.init();
    }

    init(){
        store.state.globalSpinner++;
        ChatRoomClient.getById(this.$route.params.identifier.toString())
        .then(x => {
            this.loaded = true;
            this.chatRoomVm = x;
            ChatRoomClient.getAdmins(this.$route.params.identifier.toString())
            .then(x => {
                this.isAdmin = x.includes(store.state.user.identifier);
            })
        })
        .finally(() => {
            console.log(this.chatRoomVm);
            store.state.globalSpinner--;
        });
    }

    openEditModal(){
        this.$opModal.show(EditModal, {
            chatRoomVm: this.chatRoomVm
        }, () => this.init());
    }

    openSettingsModal(){
        this.$opModal.show(SettingsModal, {
            chatRoomIdentifier: this.chatRoomVm.identifier
        }, () => this.init());
    }
    
    openPerson(item: OM.NameIdentifier){
        if(item.identifier == store.state.user.identifier)
            return;
        
        this.$router.push('/profile/' + item.identifier);
    }

    openPeopleModal(){
        store.state.allPeoplePage.title = this.chatRoomVm.name;
        store.state.allPeoplePage.model = this.chatRoomVm.participants;
        this.$router.push('/allPeople/true');
    }

    @Watch('chatRoomVm.isSubscribed')
    setSubscribtion() {
        if(this.chatRoomVm.isSubscribed){
            ChatRoomClient.subscribe(this.chatRoomVm.identifier, this.$route.params.identifier.toString());
        } else {
            ChatRoomClient.unSubscribe(this.chatRoomVm.identifier, this.$route.params.identifier.toString());
        }
    }

}

