
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import WhoCanModal from './whoCanModal.vue';
import PermissionsModal from '@/components/permissionsModal.vue';
import ConfirmModal from '@/components/confirmModal.vue';
import store from '@/store';
import { ChatRoomClient } from '@/services/Services';
import { LocalizationServices } from '@/services/LocalizationServices';
import { Prop } from 'vue-property-decorator';


@Options({})
export default class SettingsModal extends Vue {

    @Prop() chatRoomIdentifier: string;

    permissions(){
        ChatRoomClient.getPermissions(this.chatRoomIdentifier)
        .then(x => {
            var title = LocalizationServices.getLocalizedValue("app_impostazionigruppo", "Impostazioni gruppo");
            var infoLabel = LocalizationServices.getLocalizedValue("app_possonomodificareleinformazioni", "Possono modificare le informazioni");
            var invitationLabel = LocalizationServices.getLocalizedValue("app_possonoinvitareutenti", "Possono invitare utenti");
            var contentLabel = LocalizationServices.getLocalizedValue("app_possonoscrivereinchat", "Possono scrivere in chat");

            this.$opModal.show(PermissionsModal, {
                title: title,
                infoLabel: infoLabel,
                invitationLabel: invitationLabel,
                contentLabel: contentLabel,
                permissions: x || new OM.PermissionSettings(),
                saveCb: (model: OM.PermissionSettings) => {
                    store.state.globalSpinner++;
                    ChatRoomClient.setPermissions({
                        identifier: this.chatRoomIdentifier,
                        value: model
                    })
                    .then(x => this.$opModal.closeLast())
                    .finally(() => store.state.globalSpinner--)
                }
            })
        })
    }

    administrators(){
        ChatRoomClient.getAdmins(this.chatRoomIdentifier)
        .then(x => {
            this.$opModal.show(WhoCanModal, {
                chatRoomIdentifier: this.chatRoomIdentifier,
                model: x,
                saveCb: (selected: VM.NameIdentifierSelected[]) => {
                    store.state.globalSpinner++;
                    ChatRoomClient.setAdmins({
                        identifier: this.chatRoomIdentifier,
                        values: selected.map(c => c.identifier)
                    })
                    .then(x => this.$opModal.closeAll())
                    .finally(() => store.state.globalSpinner--)
                }
            })
        })
    }

    moderators(){
        ChatRoomClient.getModerators(this.chatRoomIdentifier)
        .then(x => {
            this.$opModal.show(WhoCanModal, {
                chatRoomIdentifier: this.chatRoomIdentifier,
                model: x,
                saveCb: (selected: VM.NameIdentifierSelected[]) => {
                    store.state.globalSpinner++;
                    ChatRoomClient.setModerators({
                        identifier: this.chatRoomIdentifier,
                        values: selected.map(c => c.identifier)
                    })
                    .then(x => this.$opModal.closeAll())
                    .finally(() => store.state.globalSpinner--)
                }
            })
        })
    }
    
    
    removeGroup(){
        var text = LocalizationServices.getLocalizedValue("app_vuoieliminarequestogruppo", "Vuoi eliminare questo gruppo?");
        this.$opModal.show(ConfirmModal, {
            text: text,
            okCb: () => {
                ChatRoomClient.removeAllPartecipants(this.chatRoomIdentifier)
                .then( x =>{
                    this.$opModal.closeAll();
                    this.$router.replace("/chats");
                })
            },
            koCb: () => {
                this.$opModal.closeAll();
            }
        })
    }

}
